import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { graphql } from 'gatsby'

// import Masonry from 'react-masonry-component'
// import { StaticQuery, Img, graphql } from 'gatsby'
import { Button, Icon, Grid, Container, Divider, Card } from 'semantic-ui-react'
import ResEmbeddedVimeo from '../components/ResEmbeddedVimeo'

import AccessoryCatalog from '../components/AccessoryCatalog'
import SpaChairCatalog from '../components/SpaChairCatalog'
import SEO from '../components/SEO'
import ContegoMeaning from '../images/logos/contego-meaning.png'
import Logo from '../images/logos/contego-logo.png'
import Timeline from '../images/general/timeline-banner-simple.png'
import JetLiner from '../images/general/jet-liner.png'
import AccessoryChairs from '../components/AccessoryChairs'

import RosegoldBowl from '../images/bowl-images/rosegold.jpg'
import GoldBowl from '../images/bowl-images/gold.jpg'
import PearlBowl from '../images/bowl-images/pearl.jpg'
import MochaBowl from '../images/bowl-images/mocha.jpg'
import GraphiteBowl from '../images/bowl-images/graphite.jpg'

import IvoryLeather from '../images/chair-leathers/ivory.png'
import BlackLeather from '../images/chair-leathers/black.png'
import CappuccinoLeather from '../images/chair-leathers/cappuccino.png'
import FossilgreyLeather from '../images/chair-leathers/fossil_grey.png'
import DarkchocolateLeather from '../images/chair-leathers/dark_chocolate.png'
import BurgundyLeather from '../images/chair-leathers/burgundy.png'
import CatalogGallery from '../components/CatalogGallery'
import ProductView from '../components/ProductView'
import Furniture from '../components/Furniture'

const SPA_CHAIR_LIST = [
  {
    model: 'majestic',
    displayName: 'MAJESTIC',
    price: '5,995',
    defaultChairColor: 'fossil_grey',
    defaultBowlColor: 'pearl'
  },
  {
    model: 'magna',
    displayName: 'MAGNA',
    price: '4,450',
    defaultChairColor: 'ivory',
    defaultBowlColor: 'rose_gold'
  },
  {
    model: 'vip',
    displayName: 'VIP',
    price: '4,995',
    defaultChairColor: 'ivory',
    defaultBowlColor: 'pearl'
  },
  {
    model: 'vsportn',
    displayName: 'V-SPORT-N',
    price: '3,650',
    defaultChairColor: 'fossil_grey',
    defaultBowlColor: 'pearl'
  },
  {
    model: 'vsportt',
    displayName: 'V-SPORT-T',
    price: '3,750',
    defaultChairColor: 'fossil_grey',
    defaultBowlColor: 'pearl'
  },
  {
    model: 'tergo',
    displayName: 'TERGO',
    price: '3,550',
    defaultChairColor: 'burgundy',
    defaultBowlColor: 'mocha'
  },
  {
    model: 'novo',
    displayName: 'NOVO',
    price: '3,550',
    defaultChairColor: 'cappuccino',
    defaultBowlColor: 'graphite'
  },
  {
    model: 'novox',
    displayName: 'NOVO-X',
    price: '3,450',
    defaultChairColor: 'black',
    defaultBowlColor: 'gold'
  }
]

const ACCESSORY_CHAIR_LIST = [
  {
    model: 'manistool',
    name: 'MANI STOOL',
    defaultChairColor: 'burgundy',
    defaultTexture: 'regular',
    price: '175',
    bg: 'bg-periwinkle'
  },
  {
    model: 'pedistool',
    name: 'PEDI STOOL',
    defaultChairColor: 'black',
    defaultTexture: 'diamond',
    price: '225',
    bg: 'bg-paleorange'
  },
  {
    model: 'techchair',
    name: 'TECH CHAIR',
    defaultChairColor: 'cappuccino',
    defaultTexture: 'regular',
    price: '235',
    bg: 'bg-alabaster'
  },
  {
    model: 'customerchair',
    name: 'CUSTOMER CHAIR',
    defaultChairColor: 'ivory',
    defaultTexture: 'regular',
    price: '375',
    bg: 'bg-light'
  },
  {
    model: 'customerchair',
    name: 'CUSTOMER CHAIR',
    defaultChairColor: 'ivory',
    defaultTexture: 'regular',
    price: '375',
    bg: 'bg-light'
  }
]

const BOWLS = [
  { color: 'Rose Gold', src: RosegoldBowl },
  { color: 'Gold', src: GoldBowl },
  { color: 'Pearl', src: PearlBowl },
  { color: 'Graphite', src: GraphiteBowl },
  { color: 'Mocha', src: MochaBowl }
]

const LEATHERS = [
  { color: 'Burgundy', src: BurgundyLeather },
  { color: 'Black', src: BlackLeather },
  { color: 'Cappuccino', src: CappuccinoLeather },
  { color: 'Dark Chocolate', src: DarkchocolateLeather },
  { color: 'Fossil Grey', src: FossilgreyLeather },
  { color: 'Ivory', src: IvoryLeather }
]

export const pageQuery = graphql`
  {
    NailTableTower1: file(
      relativePath: { eq: "furniture/nail-tables/tower/nail_table_tower_close_draw.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          width: 500
          height: 500
          quality: 100
          layout: CONSTRAINED
        )
      }
    }
    NailTableTower2: file(
      relativePath: { eq: "furniture/nail-tables/tower/nail_table_tower_open_draw.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          width: 500
          height: 500
          quality: 100
          layout: CONSTRAINED
        )
      }
    }
    NailTable35InchesOne: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesTwo: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesThree: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesFour: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesFive: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesSix: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesOne: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesTwo: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesThree: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesFour: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesFive: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesSix: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`
const DigitalCatalog = props => {
  return (
    <>
      <SEO
        title="Contego Digital Catalog"
        description="Featuring All Contego Products"
        meta={[
          {
            name: 'keywords',
            content: 'Catalog, Contego Catalog'
          }
        ]}
      />

      <MobileNav />

      <div className="catalog-container">
        <Container
          fluid
          className="d-flex justify-content-between align-items-center flex-wrap py-4"
        >
          <img src={Logo} alt="contego logo" className="m-0" width="229px" height="50px" />
          <h1>DIGITAL CATALOG</h1>
        </Container>

        <Divider />

        <Grid columns="1" id="company-introduction" stackable className="px-2">
          <Grid.Column>
            <h1>I. INTRODUCTION</h1>
            <p>
              <img src={ContegoMeaning} alt="Contego means to protect" />{' '}
              <strong className="text-secondary">means to PROTECT</strong>. At Contégo Spa Designs
              Inc., our mission is to revolutionize the pedicure industry with our patented pedicure
              spa technologies. We are confident in the quality of our products and strive to give
              you the best customer service. Every Contégo product is designed with unique
              user-friendly features, while performing at the highest sanitation standards.
            </p>
          </Grid.Column>
        </Grid>

        <div id="industry-evolution" className="py-4 px-2">
          <Grid columns="1" stackable>
            <Grid.Column>
              <h1>II. INDUSTRY EVOLUTION</h1>
              <p>35 YEARS OF IMPROVEMENT</p>
              <img src={Timeline} alt="timeline" width="100%" height="auto" />
              <p>
                Industry had been struggling with sanitation issue (Cross-Contamination) without any
                solution.
              </p>

              <p>
                Until 2015,{' '}
                <strong className="text-secondary">
                  CONTÉGO SPA DESIGNS pioneered THE WORLD FIRST 100% DISPOSABEL JET and FEATUREFUL
                  SPA CHAIRS.
                </strong>{' '}
                Together they changed the entire sanitation standard of the pedicure industry.
              </p>
            </Grid.Column>
          </Grid>

          <Grid columns="16" stackable verticalAlign="middle">
            <Grid.Column width="4">
              <h3 className="mb-0">
                DISPOSABLE JET{' '}
                <p>
                  <strong className="text-secondary">(Includes Attached Liner)</strong>
                </p>
              </h3>
              <img src={JetLiner} alt="jet liner" className="img-fluid" />
            </Grid.Column>
            <Grid.Column width="12">
              <h3 className="text-secondary mt-0">
                $0.55/Piece <strong className="text-dark">or</strong> $110/Box (200 Pieces)
              </h3>
              <p>
                <strong className="text-secondary">100% Disposable Jet</strong> is a Contégo
                patented technology which <strong>PREVENTS CROSS-CONTAMINATION</strong> while still
                maintaining <strong>a SENSATIONAL FOOT MASSAGE</strong>
              </p>
            </Grid.Column>
          </Grid>
        </div>

        <div id="technologies" className="py-4 px-2">
          <Grid stackable columns="1">
            <Grid.Column>
              <h1>III. TECHNOLOGIES</h1>
              <p>
                After going through years of experiments, CONTÉGO developed and manufactered the
                world most sophisticated spa chairs integrated with three patented features:
              </p>

              <div className="py-4">
                <h3 className="text-secondary">100% DISPOSABLE JET</h3>
                <p>
                  Efficient air jet massage system integrated with liner to protect customer&apos;s
                  feet from Germs and Bacterias in the bowl and minimize the preparation time
                  between each service.
                </p>
                <ResEmbeddedVimeo
                  full
                  url="https://player.vimeo.com/video/434470106"
                  width="100%"
                  height="100%"
                />
              </div>

              <div className="py-4">
                <h3 className="text-secondary">EASY DRAIN</h3>
                <p>
                  Water Contactless Draignage is to protect the nail technician’s hands from being
                  exposed to bacteria and fungus inside water from each service.
                </p>
                <ResEmbeddedVimeo
                  full
                  url="https://player.vimeo.com/video/434470139"
                  width="100%"
                  height="100%"
                />
              </div>

              <div className="py-4">
                <h3 className="text-secondary">INFINITY OVERFLOW</h3>
                <p>
                  To prevent water from overflowing out of the bowl and in your salon. You do not
                  have to worry about sensor or electrical parts failure because our system is 0%
                  dependents on electrical components and sensors.
                </p>
                <ResEmbeddedVimeo
                  full
                  url="https://player.vimeo.com/video/434470166"
                  width="100%"
                  height="100%"
                />
              </div>
            </Grid.Column>
          </Grid>
        </div>

        <div className="pt-4 bg-light" id="spa-chairs">
          <h1 className="text-center py-4">IV. PEDICURE SPA CHAIRS</h1>

          <div className="text-light bg-dark py-5">
            <h2 className="text-center">CHAIR LEATHERS</h2>
            <p className="text-center px-4">
              All CONTÉGO leathers are accestone resistant. Please refer to our leather care guide
              for maintaining.
            </p>

            <div className="d-flex flex-wrap align-items-center justify-content-center">
              {LEATHERS.map(leather => {
                return (
                  <div key={leather.color} role="button" className="button px-2 py-2">
                    <img
                      src={leather.src}
                      width="150px"
                      height="150px"
                      alt={`${leather.color} bowl`}
                      className="rounded-circle"
                    />
                    <h5 className="text-center my-0 pt-2">{_.upperCase(leather.color)}</h5>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="shadow-sm bg-white py-5">
            <h2 className="text-center">BOWLS</h2>
            <p className="text-center px-4">
              Our beautiful high gloss and textured bowls are made from fiberglass to ensure the
              beauty and durability due to its heavy usage in the pedicure spa chairs.{' '}
            </p>

            <div className="d-flex flex-wrap align-items-center justify-content-center">
              {BOWLS.map(bowl => {
                return (
                  <div key={bowl.color} role="button" className="button px-2 py-2">
                    <img
                      src={bowl.src}
                      width="100px"
                      height="100px"
                      alt={`${bowl.color} bowl`}
                      className="rounded-circle"
                    />
                    <h5 className="text-center">{_.upperCase(bowl.color)}</h5>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="shadow-sm py-5">
            <Grid columns="equal" stackable>
              <Grid.Column>
                <h2 className="text-center">ADJUSTABLE FOOTREST</h2>
                <ResEmbeddedVimeo
                  full
                  url="https://player.vimeo.com/video/434470066"
                  width="100%"
                  height="100%"
                />
              </Grid.Column>
            </Grid>
          </div>

          <br />

          {SPA_CHAIR_LIST.map(spaChair => (
            <SpaChairCatalog
              key={spaChair.model}
              model={spaChair.model}
              displayName={spaChair.displayName}
              price={spaChair.price}
              defaultChairColor={spaChair.defaultChairColor}
              defaultBowlColor={spaChair.defaultBowlColor}
            />
          ))}

          <CatalogGallery />
        </div>

        <br />

        <div id="accessory-chairs" className="container-fluid">
          <h1 className="text-center">V. ACCESSORY CHAIRS</h1>

          {ACCESSORY_CHAIR_LIST.map(accessoryChair => (
            <div className="shadow-sm py-4" key={accessoryChair.model}>
              <AccessoryCatalog
                model={accessoryChair.model}
                name={accessoryChair.name}
                defaultChairColor={accessoryChair.defaultChairColor}
                defaultTexture={accessoryChair.defaultTexture}
                price={accessoryChair.price}
                bg={accessoryChair.bg}
              />
            </div>
          ))}

          <AccessoryChairs
            model="customerchairsofa"
            name="Customer Chair - Sofa Style"
            des="customer sofa style chair"
            defaultChairColor="black"
            defaultTexture="regular"
            price="495"
          />

          <Furniture name="DIP POWDER CABINET" model="DipPowderCabinet" price="995" />
          <Furniture name="GEL POLISH CABINET" model="GelPolishCabinet" price="995" />
          <ProductView
            price="945"
            name='NAIL TABLE 35"'
            items={[
              {
                title: 'Nail Table 35" inches close draws',
                type: 'img',
                url: props.data.NailTable35InchesOne.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" side view',
                type: 'img',
                url: props.data.NailTable35InchesTwo.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened left draw',
                type: 'img',
                url: props.data.NailTable35InchesThree.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened center draw',
                type: 'img',
                url: props.data.NailTable35InchesFour.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened right draw',
                type: 'img',
                url: props.data.NailTable35InchesFive.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened draws',
                type: 'img',
                url: props.data.NailTable35InchesSix.childImageSharp.gatsbyImageData
              }
            ]}
          />
          <ProductView
            price="995"
            name='NAIL TABLE 38"'
            items={[
              {
                title: 'Nail Table 38" inches close draws',
                type: 'img',
                url: props.data.NailTable38InchesOne.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" side view',
                type: 'img',
                url: props.data.NailTable38InchesTwo.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened left draw',
                type: 'img',
                url: props.data.NailTable38InchesThree.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened center draw',
                type: 'img',
                url: props.data.NailTable38InchesFour.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened right draw',
                type: 'img',
                url: props.data.NailTable38InchesFive.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened draws',
                type: 'img',
                url: props.data.NailTable38InchesSix.childImageSharp.gatsbyImageData
              }
            ]}
          />

          <ProductView
            price="495"
            name="LUNA NAIL TABLE TOWER"
            items={[
              {
                title: 'Nail Table Tower Close Draw',
                type: 'img',
                url: props.data.NailTableTower1.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table Tower Open Draw',
                type: 'img',
                url: props.data.NailTableTower2.childImageSharp.gatsbyImageData
              }
            ]}
          />
        </div>

        <div className="py-4" id="contact-us">
          <h1 className="text-center">VI. CONTACT INFO</h1>

          <Grid columns="2" stackable padded>
            <Grid.Column>
              <Card fluid>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5519.927823646978!2d-117.95541615301237!3d33.774299626182454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2614190e99f7%3A0x7866e29281e323a1!2sContego%20Spa%20Designs!5e0!3m2!1sen!2sus!4v1570034391494!5m2!1sen!2sus"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  className="m-0"
                  title="garden grove showroom"
                />
                <Card.Content className="text-center py-4">
                  <h2 className="text-center">GARDEN GROVE, CA</h2>
                  <Card.Description className="text-center text-large">
                    <a
                      href="https://goo.gl/maps/z7iY7nsy7sLVv1f28"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-center"
                    >
                      12856 Brookhurst St, Garden Grove, CA 92840
                    </a>
                    <br />
                    <br />

                    <p className="d-block mb-0">
                      <strong className="text-secondary">MON-FRI</strong> 10:00AM - 6:00PM
                    </p>
                    <p className="d-block mb-0">
                      <strong className="text-secondary">SAT</strong> 11:00AM - 3:00PM
                    </p>
                    <p className="d-block mb-0">
                      <strong className="text-secondary">SUN</strong> 10:00AM - 5:00PM
                    </p>
                    <br />

                    <a href="tel:714-895-8865" className="text-center my-4">
                      (714) 895-8865
                    </a>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>

            <Grid.Column>
              <Card fluid>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9751.037284441582!2d-95.56976097480528!3d29.703475401994872!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd96ba00d563e7808!2sCONTEGO%20SPA%20DESIGNS%20Houston%20Showroom!5e0!3m2!1sen!2sus!4v1570118953949!5m2!1sen!2sus"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  className="m-0"
                  title="texas showroom"
                />
                <Card.Content className="text-center py-4">
                  <h2 className="text-center">HOUSTON, TX</h2>
                  <Card.Description className="text-center text-large">
                    <a
                      href="https://goo.gl/maps/tS79wr6Dnm8vazNf8"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-center"
                    >
                      6787A Wilcrest Dr Suite A, Houston, TX 77072
                    </a>
                    <br />

                    <br />
                    <p className="d-block mb-0">
                      <strong className="text-secondary">MON - FRI</strong> 10:00AM - 6:00PM
                    </p>
                    <p className="d-block mb-0">
                      <strong className="text-secondary"> SAT</strong> CLOSED
                    </p>
                    <p className="d-block mb-0">
                      <strong className="text-secondary"> SUN</strong> 11:00AM - 5:00PM
                    </p>
                    <br />

                    <a href="tel:832-436-2300" className="text-center py-2">
                      (832) 436-2300
                    </a>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
        </div>
      </div>
      <br />
      <br />
    </>
  )
}

const MobileNav = () => {
  const [isActive, setIsActive] = React.useState(false)

  const toggleMenu = React.useCallback(() => {
    setIsActive(isActive => !isActive)
  }, [])

  const handleNavigate = React.useCallback(() => {
    setIsActive(false)
  }, [])

  return (
    <>
      <div className="mobile-bottom-nav shadow">
        <div
          className={classnames([
            'mobile-nav-container shadow-sm animated slideInLeft faster',
            { active: isActive }
          ])}
        >
          <ul>
            <li>
              <a onClick={handleNavigate} href="#company-introduction">
                I. INTRODUCTION
              </a>
            </li>
            <li>
              <a onClick={handleNavigate} href="#industry-evolution">
                II. INDUSTRY EVOLUTION
              </a>
            </li>
            <li>
              <a onClick={handleNavigate} href="#technologies">
                III. TECHNOLOGIES
              </a>
            </li>
            <li>
              <a onClick={handleNavigate} href="#spa-chairs">
                IV. SPA CHAIRS
              </a>
            </li>
            <li>
              <a onClick={handleNavigate} href="#accessory-chairs">
                V. ACCESSORY CHAIRS
              </a>
            </li>
            {/* <li>
              <a onClick={handleNavigate} href="#furnitures">VI. FUNITURES</a>
            </li>
            <li>
              <a onClick={handleNavigate} href="#accessory">VII. ACCESSORY</a>
            </li> */}
            <li>
              <a onClick={handleNavigate} href="#contact-us">
                VI. CONTACT INFO
              </a>
            </li>
          </ul>
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <button
              className={classnames('menu-icon hamburger hamburger--emphatic rounded', {
                'is-active': isActive
              })}
              type="button"
              onClick={toggleMenu}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            <span>MENU</span>
          </div>

          <Button as="a" href="#company-introduction" icon basic className="mr-2">
            <Icon name="home" /> HOME
          </Button>
        </div>
      </div>
    </>
  )
}

export default DigitalCatalog
