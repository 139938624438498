import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import classnames from 'classnames'
import _ from 'lodash'
import Masonry from 'react-masonry-component'
import { Loader, Modal, Button, Icon } from 'semantic-ui-react'

export default () => {
  return (
    <StaticQuery
      query={graphql`
        {
          showcase1: file(relativePath: { eq: "showcases/showcase-1.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase2: file(relativePath: { eq: "showcases/showcase-2.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase3: file(relativePath: { eq: "showcases/showcase-3.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase4: file(relativePath: { eq: "showcases/showcase-4.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase5: file(relativePath: { eq: "showcases/showcase-5.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase6: file(relativePath: { eq: "showcases/showcase-6.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase7: file(relativePath: { eq: "showcases/showcase-7.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase8: file(relativePath: { eq: "showcases/showcase-8.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase9: file(relativePath: { eq: "showcases/showcase-9.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase10: file(relativePath: { eq: "showcases/showcase-10.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase11: file(relativePath: { eq: "showcases/showcase-11.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase12: file(relativePath: { eq: "showcases/showcase-12.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase13: file(relativePath: { eq: "showcases/showcase-13.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase14: file(relativePath: { eq: "showcases/showcase-14.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase15: file(relativePath: { eq: "showcases/showcase-15.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase16: file(relativePath: { eq: "showcases/showcase-16.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase17: file(relativePath: { eq: "showcases/showcase-17.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase18: file(relativePath: { eq: "showcases/showcase-18.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase19: file(relativePath: { eq: "showcases/showcase-19.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase20: file(relativePath: { eq: "showcases/showcase-20.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase21: file(relativePath: { eq: "showcases/showcase-21.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase22: file(relativePath: { eq: "showcases/showcase-22.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase23: file(relativePath: { eq: "showcases/showcase-23.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
          showcase24: file(relativePath: { eq: "showcases/showcase-24.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={data => <CatalogGallery data={data} />}
    />
  )
}

const CatalogGallery = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const [visible, setVisible] = useState(false)

  const SHOWCASE_IMAGES = useMemo(() => {
    const storage = []
    for (let i = 0; i < 24; i++) {
      storage.push({
        index: i,
        imgLink: data[`showcase${i + 1}`].childImageSharp.gatsbyImageData
      })
    }
    return storage
  }, [data])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  })

  const handleShowGallery = useCallback(() => {
    setVisible(true)
  }, [])

  const handleZoomImage = useCallback(e => {
    const { index } = e.currentTarget.dataset
    setActiveIndex(index)
  }, [])

  const handleKeyDown = useCallback(
    e => {
      if (activeIndex === -1) {
        return
      }

      if (e.key === 'ArrowLeft' || e.keyCode === 37) {
        setActiveIndex(Math.max(activeIndex - 1, 0))
      } else if (e.key === 'ArrowRight' || e.keyCode === 39) {
        setActiveIndex(Math.min(activeIndex + 1, SHOWCASE_IMAGES.length - 1))
      }
    },
    [activeIndex, SHOWCASE_IMAGES.length]
  )

  const handleNavigate = useCallback(
    e => {
      const { direction } = e.currentTarget.dataset

      if (activeIndex === -1) {
        return
      }

      if (direction === 'prev') {
        setActiveIndex(Math.max(activeIndex - 1, 0))
      } else if (direction === 'next') {
        setActiveIndex(Math.min(activeIndex + 1, SHOWCASE_IMAGES.length - 1))
      }
    },
    [activeIndex, SHOWCASE_IMAGES.length]
  )

  const handleCloseGallery = useCallback(() => {
    setActiveIndex(-1)
  }, [])

  useEffect(() => {
    if (activeIndex >= 0) {
      const activeThumbnail = document.getElementById(`thumbnail_${activeIndex}`)
      activeThumbnail.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  }, [activeIndex])

  // const handleTouchStart = useCallback(e => {
  //   console.log(e.touches[0])
  // }, [])
  // const handleTouchMove = useCallback(e => {
  //   console.log(e.changedTouches)
  // }, [])
  // const handleTouchEnd = useCallback(e => {
  //   console.log(e.changedTouches)
  // }, [])
  // const handleTouchCancel = useCallback(e => {
  //   console.log(e.changedTouches)
  // }, [])

  return (
    <>
      <div className="container-fluid py-5">
        <div className="row pb-5">
          <div className="col text-center">
            <h1>GALLERY</h1>
            <p>
              Showcases of Nail salons using our pedicure spa massage chairs to protect their
              beloved customers. Contego Pedicure Spa Massage Chairs for Cleaner, Safer and Faster
              sanitation.
            </p>
          </div>
        </div>

        <div className="row">
          <Masonry
            className="w-100 mx-auto"
            options={{ transitionDuration: 3000, fitWidth: true }}
            onImagesLoaded={handleShowGallery}
            // onLayoutComplete={handleShowGallery}
          >
            {visible ? (
              SHOWCASE_IMAGES.map((item, i) => {
                return (
                  <div
                    role="button"
                    tabIndex={i}
                    onKeyPress={handleKeyDown}
                    data-index={item.index}
                    className="grid-item catalog-gallery p-1 clickable"
                    key={`item ${item.index}`}
                    onClick={handleZoomImage}
                  >
                    <GatsbyImage image={item.imgLink} alt="" />
                  </div>
                )
              })
            ) : (
              <Loader active inline="centered" />
            )}
          </Masonry>

          <a
            href="https://www.contegospa.com/gallery"
            className="btn-basic mx-auto mt-4 p-2 rounded"
          >
            VIEW MORE
          </a>
        </div>
      </div>

      <Modal size="fullscreen" open={activeIndex !== -1}>
        <div className="zoom-view-header">
          <h2 className="ml-2 mb-0">GALLERY</h2>

          <Icon name="close" size="large" color="red" onClick={handleCloseGallery} />
        </div>

        <div className="zoom-view-content">
          {activeIndex >= 0 && (
            <img
              src={_.filter(SHOWCASE_IMAGES, { index: Number(activeIndex) })[0].imgLink.src}
              alt={`img_${activeIndex}`}
              className="zoom-view-img"
              // onTouchStart={handleTouchStart}
              // onTouchMove={handleTouchMove}
              // onTouchEnd={handleTouchEnd}
              // onTouchCancel={handleTouchCancel}
            />
          )}

          {activeIndex > 0 && (
            <Icon
              name="chevron left"
              data-direction="prev"
              size="big"
              className="zoom-view-prev-btn"
              onClick={handleNavigate}
            />
          )}

          {activeIndex !== SHOWCASE_IMAGES.length - 1 && (
            <Icon
              name="chevron right"
              data-direction="next"
              size="big"
              className="zoom-view-next-btn"
              onClick={handleNavigate}
            />
          )}
        </div>
        <h6 className="my-0 px-2 w-100 text-center">{`<<<<<<<< SWIPE TO VIEW MORE >>>>>>`}</h6>

        {activeIndex >= 0 && (
          <div className="zoom-view-thumbnail-container">
            {SHOWCASE_IMAGES.map(item => {
              return (
                <Button
                  style={{ backgroundImage: `url(${item.imgLink.src})` }}
                  className={classnames(['zoom-view-thumbnail'], {
                    active: item.index === activeIndex
                  })}
                  data-index={item.index}
                  key={`item_${item.index}`}
                  id={`thumbnail_${item.index}`}
                  onClick={() => setActiveIndex(item.index)}
                />
              )
            })}
          </div>
        )}
      </Modal>
    </>
  )
}
