import React from 'react'
import classNames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, Grid } from 'semantic-ui-react'
import _ from 'lodash'

const CHAIR_COLORS = ['black', 'cappuccino', 'fossil_grey', 'ivory', 'dark_chocolate', 'burgundy']
const TEXTURES = ['regular', 'diamond']

export default ({ model, defaultChairColor, defaultTexture, price, name, bg }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          Pedistoolcappuccino: file(relativePath: { eq: "pedi-stool/pedistool-cappuccino.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolivory: file(relativePath: { eq: "pedi-stool/pedistool-ivory.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolfossilgrey: file(relativePath: { eq: "pedi-stool/pedistool-fossilgrey.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolburgundy: file(relativePath: { eq: "pedi-stool/pedistool-burgundy.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistooldarkchocolate: file(
            relativePath: { eq: "pedi-stool/pedistool-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolblack: file(relativePath: { eq: "pedi-stool/pedistool-black.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolcappuccinodiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-cappuccino-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolivorydiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-ivory-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolfossilgreydiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-fossilgrey-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolburgundydiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-burgundy-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistooldarkchocolatediamond: file(
            relativePath: { eq: "pedi-stool/pedistool-darkchocolate-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolblackdiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-black-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchaircappuccino: file(relativePath: { eq: "tech-chair/techchair-cappuccino.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairivory: file(relativePath: { eq: "tech-chair/techchair-ivory.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairfossilgrey: file(relativePath: { eq: "tech-chair/techchair-fossilgrey.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairburgundy: file(relativePath: { eq: "tech-chair/techchair-burgundy.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairdarkchocolate: file(
            relativePath: { eq: "tech-chair/techchair-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairblack: file(relativePath: { eq: "tech-chair/techchair-black.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchaircappuccinodiamond: file(
            relativePath: { eq: "tech-chair/techchair-cappuccino-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairivorydiamond: file(
            relativePath: { eq: "tech-chair/techchair-ivory-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairfossilgreydiamond: file(
            relativePath: { eq: "tech-chair/techchair-fossilgrey-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairburgundydiamond: file(
            relativePath: { eq: "tech-chair/techchair-burgundy-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairdarkchocolatediamond: file(
            relativePath: { eq: "tech-chair/techchair-darkchocolate-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairblackdiamond: file(
            relativePath: { eq: "tech-chair/techchair-black-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolcappuccino: file(relativePath: { eq: "mani-stool/manistool-cappuccino.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolivory: file(relativePath: { eq: "mani-stool/manistool-ivory.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolfossilgrey: file(relativePath: { eq: "mani-stool/manistool-fossilgrey.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolburgundy: file(relativePath: { eq: "mani-stool/manistool-burgundy.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistooldarkchocolate: file(
            relativePath: { eq: "mani-stool/manistool-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolblack: file(relativePath: { eq: "mani-stool/manistool-black.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchaircappuccino: file(
            relativePath: { eq: "customer-chair/customerchair-cappuccino.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairivory: file(relativePath: { eq: "customer-chair/customerchair-ivory.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairfossilgrey: file(
            relativePath: { eq: "customer-chair/customerchair-fossilgrey.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairburgundy: file(
            relativePath: { eq: "customer-chair/customerchair-burgundy.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairdarkchocolate: file(
            relativePath: { eq: "customer-chair/customerchair-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairblack: file(relativePath: { eq: "customer-chair/customerchair-black.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={data => (
        <AccessoryChairs
          data={data}
          model={model}
          defaultChairColor={defaultChairColor}
          defaultTexture={defaultTexture}
          price={price}
          name={name}
          bg={bg}
        />
      )}
    />
  )
}

const AccessoryChairs = ({ data, model, defaultChairColor, defaultTexture, price, name, bg }) => {
  const [currentChairColor, setCurrentChairColor] = React.useState(defaultChairColor)
  const [currentTexture, setCurrentTexture] = React.useState(defaultTexture)

  const CHAIR_IMAGES = React.useMemo(() => {
    return {
      pedistool: {
        cappuccino_regular: data.Pedistoolcappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Pedistoolivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Pedistoolfossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Pedistoolburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Pedistooldarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Pedistoolblack.childImageSharp.gatsbyImageData,

        cappuccino_diamond: data.Pedistoolcappuccinodiamond.childImageSharp.gatsbyImageData,
        ivory_diamond: data.Pedistoolivorydiamond.childImageSharp.gatsbyImageData,
        fossil_grey_diamond: data.Pedistoolfossilgreydiamond.childImageSharp.gatsbyImageData,
        burgundy_diamond: data.Pedistoolburgundydiamond.childImageSharp.gatsbyImageData,
        dark_chocolate_diamond: data.Pedistooldarkchocolatediamond.childImageSharp.gatsbyImageData,
        black_diamond: data.Pedistoolblackdiamond.childImageSharp.gatsbyImageData
      },
      techchair: {
        cappuccino_regular: data.Techchaircappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Techchairivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Techchairfossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Techchairburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Techchairdarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Techchairblack.childImageSharp.gatsbyImageData,

        cappuccino_diamond: data.Techchaircappuccinodiamond.childImageSharp.gatsbyImageData,
        ivory_diamond: data.Techchairivorydiamond.childImageSharp.gatsbyImageData,
        fossil_grey_diamond: data.Techchairfossilgreydiamond.childImageSharp.gatsbyImageData,
        burgundy_diamond: data.Techchairburgundydiamond.childImageSharp.gatsbyImageData,
        dark_chocolate_diamond: data.Techchairdarkchocolatediamond.childImageSharp.gatsbyImageData,
        black_diamond: data.Techchairblackdiamond.childImageSharp.gatsbyImageData
      },
      manistool: {
        cappuccino_regular: data.Manistoolcappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Manistoolivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Manistoolfossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Manistoolburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Manistooldarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Manistoolblack.childImageSharp.gatsbyImageData
      },
      customerchair: {
        cappuccino_regular: data.Customerchaircappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Customerchairivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Customerchairfossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Customerchairburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Customerchairdarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Customerchairblack.childImageSharp.gatsbyImageData
      }
    }
  }, [data])

  return (
    <Container fluid className="pb-4 rounded">
      <Grid columns="16">
        <br />
        <div className="my-4">
          <h2 className="text-uppercase text-secondary my-0">
            {model === 'pedistool' && currentTexture} {_.upperCase(currentChairColor)} {name}
          </h2>

          {model !== 'techchair' && (
            <h2 className="mt-0">$ {currentTexture === 'diamond' ? '255' : price}</h2>
          )}
        </div>

        <Grid.Column width="16" className={classNames(['px-4 py-4 rounded', `${bg}`])}>
          <GatsbyImage
            image={CHAIR_IMAGES[`${model}`][`${currentChairColor}_${currentTexture}`]}
            className="animated zoomIn slow"
            style={{
              width: '100%',
              maxWidth: '450px',
              height: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            alt=""
          />
          <h6 className="text-secondary text-center my-4">Digital Coloring May Vary</h6>

          <div className="py-2 d-flex flex-wrap justify-content-center">
            {CHAIR_COLORS.map(chairColor => {
              return (
                <button
                  key={chairColor}
                  type="button"
                  aria-label="chair color button"
                  onClick={() => setCurrentChairColor(chairColor)}
                  className={classNames(
                    'color-btn shadow',
                    `${chairColor}`,
                    'rounded-circle mr-1 mb-1',
                    {
                      'animated infinite pulse': currentChairColor === chairColor
                    }
                  )}
                />
              )
            })}
          </div>

          {model === 'pedistool' && (
            <div className="d-flex  justify-content-center flex-wrap">
              {TEXTURES.map(texture => {
                return (
                  <button
                    key={texture}
                    type="button"
                    className={classNames('action-button rounded-pill mx-1', {
                      'animated infinite pulse': currentTexture === texture
                    })}
                    onClick={() => setCurrentTexture(texture)}
                  >
                    {texture}
                  </button>
                )
              })}
            </div>
          )}
        </Grid.Column>
      </Grid>

      {currentTexture === 'regular' ? (
        <>
          <h4 className="text-center py-4">REGULAR STITCH</h4>
          <Grid columns="equal">
            <Grid.Column className="rounded mr-2 my-2">
              <div>
                <GatsbyImage
                  image={CHAIR_IMAGES[`${model}`].cappuccino_regular}
                  className="animated zoomIn slow"
                  style={{
                    width: '100%',
                    maxWidth: '450px',
                    height: 'auto',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                  alt="Cappuccino Pedistool"
                />
                <p className="text-center text-cappital text-bold">Cappuccino</p>
              </div>
            </Grid.Column>

            <Grid.Column className="rounded  my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].ivory_regular}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Ivory Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Ivory</p>
            </Grid.Column>
          </Grid>

          <Grid columns="equal">
            <Grid.Column className="rounded mr-2 my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].black_regular}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Black Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Black</p>
            </Grid.Column>
            <Grid.Column className="rounded  my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].dark_chocolate_regular}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Dark Chocolate Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Dark Chocolate</p>
            </Grid.Column>
          </Grid>

          <Grid columns="equal">
            <Grid.Column className="rounded mr-2 my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].fossil_grey_regular}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Fossil Grey Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Fossil Grey</p>
            </Grid.Column>

            <Grid.Column className="rounded my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].burgundy_regular}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Burgundy Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Burgundy</p>
            </Grid.Column>
          </Grid>
        </>
      ) : (
        <>
          <h4 className="text-center py-4">DIAMOND STITCH</h4>
          <Grid columns="equal">
            <Grid.Column className="rounded mr-2 my-2">
              <div>
                <GatsbyImage
                  image={CHAIR_IMAGES[`${model}`].cappuccino_diamond}
                  className="animated zoomIn slow"
                  style={{
                    width: '100%',
                    maxWidth: '450px',
                    height: 'auto',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                  alt="Cappuccino Pedistool"
                />
                <p className="text-center text-cappital text-bold">Cappuccino</p>
              </div>
            </Grid.Column>

            <Grid.Column className="rounded  my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].ivory_diamond}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Ivory Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Ivory</p>
            </Grid.Column>
          </Grid>

          <Grid columns="equal">
            <Grid.Column className="rounded mr-2 my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].black_diamond}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Black Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Black</p>
            </Grid.Column>
            <Grid.Column className="rounded  my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].dark_chocolate_diamond}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Dark Chocolate Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Dark Chocolate</p>
            </Grid.Column>
          </Grid>

          <Grid columns="equal">
            <Grid.Column className="rounded mr-2 my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].fossil_grey_diamond}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Fossil Grey Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Fossil Grey</p>
            </Grid.Column>

            <Grid.Column className="rounded my-2">
              <GatsbyImage
                image={CHAIR_IMAGES[`${model}`].burgundy_diamond}
                className="animated zoomIn slow"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  height: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                alt="Burgundy Pedi Stool"
              />
              <p className="text-center text-cappital text-bold">Burgundy</p>
            </Grid.Column>
          </Grid>
        </>
      )}
    </Container>
  )
}
